@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Ubuntu:wght@400;700&family=Ubuntu+Mono:wght@400;700&display=swap');

html, body { height: 100%;}

body > footer {
  position: sticky;
  top: 100vh;
}


.dropdown:hover .dropdown-menu {
  display: block;
}

a.dropdown-item {
  color: black;
}

.payment-item-image-drag{
  width: 200px;
  height: 50px;
  border: 1px dashed #aaa;
  border-radius: 10px;
}